import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import "../css/Success.css";
import { FaCheckCircle, FaLock, FaUnlock } from "react-icons/fa";

const Success = () => {
  const [windowDimension, setWindowDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimension({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleOpenApp = () => {
    window.location.href = "edutools://subscription/success";
  };

  return (
    <div className="success-page">
      <div className="confetti-container">
        <Confetti
          width={windowDimension.width}
          height={windowDimension.height}
          recycle={true}
          numberOfPieces={200}
          gravity={0.1}
        />
      </div>
      <div className="success-wrapper">
        <div className="success-content">
          <FaCheckCircle className="success-icon" />
          <h2 className="success-title">You're All Set!</h2>

          <p className="success-message">
            Your subscription has been{" "}
            <span className="blue-accent">successfully activated,</span> all
            premium content and features in the app are now{" "}
            <span className="blue-accent">fully unlocked.</span>
          </p>
          <button onClick={handleOpenApp} className="success-button">
            Return to App Now
          </button>
          <p className="success-note">
            <small>
              If the app doesn't open automatically, you may need to{" "}
              <a href="/" className="blue-accent">
                return to the website
              </a>
              .
            </small>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Success;
