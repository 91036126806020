import React from 'react';
import { Card, CardContent, Avatar, Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';

/**
 * A card component that displays a user testimonial
 */
const TestimonialCard = ({ testimonial, index }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: false, margin: "-100px" }}
      transition={{ duration: 0.6, delay: index * 0.1 }}
    >
      <Card 
        className="TestimonialCard"
        elevation={0}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          background: 'radial-gradient(circle at 50% 200%, #34353919, #17181a00 61%), radial-gradient(circle at 50% -60%, #3435394a, #17181a00 75%)',
          backdropFilter: 'blur(10px)',
          borderRadius: '16px',
          border: '1px solid rgba(255, 255, 255, 0.102)',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-5px)',
          }
        }}
      >
        <CardContent>
          <div className="TestimonialHeader" style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
            gap: '16px'
          }}>
            <div className="UserInfo" style={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px'
            }}>
              <Avatar
                src={testimonial.userImage.src}
                alt={testimonial.userImage.alt}
                sx={{ width: 60, height: 60 }}
              />
              <Box>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 600, color: "white" }}
                >
                  {testimonial.userName}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                >
                  {testimonial.userPosition}
                </Typography>
              </Box>
            </div>
            <Avatar
              src={testimonial.companyLogo.src}
              alt={testimonial.companyLogo.alt}
              variant="square"
              sx={{
                width: 80,
                height: 80,
                backgroundColor: "transparent",
                padding: 1.5,
                "& img": {
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  objectPosition: "center",
                },
              }}
            />
          </div>
          <Typography
            variant="body1"
            sx={{
              mt: 2,
              fontStyle: "italic",
              color: "white",
            }}
          >
            "{testimonial.testimonialText}"
          </Typography>
        </CardContent>
      </Card>
    </motion.div>
  );
};

export default TestimonialCard; 