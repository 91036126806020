import React from "react";

function TermsOfService() {
  return (
    <div className="container" style={{ padding: "40px 20px" }}>
      <div style={{ maxWidth: "800px", margin: "0 auto", textAlign: "left" }}>
        <h1 style={{ textAlign: "center", marginBottom: "10px" }}>Terms of Service</h1>
        <p style={{ textAlign: "center", marginBottom: "30px" }}>
          <strong>Last Updated: Jan 6, 2025</strong>
        </p>

        <div style={{ lineHeight: "1.6", fontSize: "16px" }}>
          <p style={{ marginBottom: "20px" }}>Welcome to LearnOS LLC ("Company," "we," "us," or "our"). These Terms of Service ("Terms") constitute a legally binding agreement governing your use of our educational tools and services (collectively, the "Service"). By accessing or utilizing the Service, you acknowledge and agree to be bound by these Terms.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>1. Acceptance of Terms</h2>
          <p style={{ marginBottom: "20px" }}>By registering for, accessing, or using our Service, you affirm that you have read, understood, and consented to these Terms and our Privacy Policy. If you do not agree with any provision of these Terms, you must immediately discontinue use of the Service.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>2. Eligibility</h2>
          <p style={{ marginBottom: "10px" }}>To access the Service, you must be at least 13 years old or meet the minimum age of digital consent in your jurisdiction.</p>
          <p style={{ marginBottom: "10px" }}>If you are under 18, you may use the Service only with verifiable parental or guardian consent.</p>
          <p style={{ marginBottom: "20px" }}>By using the Service, you represent that you meet these eligibility requirements and have the legal capacity to enter into this agreement.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>3. Account Registration & Security</h2>
          <p style={{ marginBottom: "10px" }}>Users may be required to establish an account to access specific features.</p>
          <p style={{ marginBottom: "10px" }}>You are solely responsible for safeguarding your account credentials and assume full liability for all activities conducted under your account.</p>
          <p style={{ marginBottom: "10px" }}>Any unauthorized use of your account or security breach must be reported to us immediately.</p>
          <p style={{ marginBottom: "10px" }}>LearnOS LLC reserves the right to suspend or terminate accounts at its discretion if they are compromised, misused, or otherwise in violation of these Terms.</p>
          <p style={{ marginBottom: "20px" }}>Unauthorized sharing of accounts or credentials is strictly prohibited and may result in immediate termination, hardware bans, or other remedial measures.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>4. Subscription & Payment</h2>
          <p style={{ marginBottom: "15px" }}>The Service operates on a subscription-based model, with the following available plans:</p>
          <ul style={{ marginBottom: "20px", paddingLeft: "30px" }}>
            <li style={{ marginBottom: "8px" }}>Monthly plan: $19.99 per month on an auto-renewal basis.</li>
            <li style={{ marginBottom: "8px" }}>Semesterly plan: $49.99 billed every four months.</li>
          </ul>
          <p style={{ marginBottom: "10px" }}>No free trials are offered.</p>
          <p style={{ marginBottom: "10px" }}>All subscription fees are non-refundable unless otherwise required by applicable law.</p>
          <p style={{ marginBottom: "10px" }}>Users may cancel their subscription at any time. However, upon cancellation, all premium access and associated benefits will be immediately revoked.</p>
          <p style={{ marginBottom: "10px" }}>Subscription fees may be adjusted at the sole discretion of LearnOS LLC, with prior notice provided to subscribers. It is the user's responsibility to look out for updates regarding subscription fees.</p>
          <p style={{ marginBottom: "10px" }}>Any additional fees imposed by third-party payment providers are the user's responsibility.</p>
          <p style={{ marginBottom: "10px" }}>Users may update their payment methods and billing information by contacting our support team.</p>
          <p style={{ marginBottom: "20px" }}>If a payment method fails, a limited grace period may be provided before suspension of service. LearnOS LLC is under no obligation to extend access beyond this period.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>5. User Conduct & Acceptable Use</h2>
          <p style={{ marginBottom: "15px" }}>Users are solely responsible for their actions within the Service. LearnOS LLC assumes no liability for any user-generated content, interactions, or third-party engagements facilitated through the platform. By using the Service, you agree not to:</p>
          <ul style={{ marginBottom: "20px", paddingLeft: "30px" }}>
            <li style={{ marginBottom: "8px" }}>Systematically extract, compile, or reproduce data without our express written permission.</li>
            <li style={{ marginBottom: "8px" }}>Mislead, defraud, or engage in deceptive practices against LearnOS LLC or other users.</li>
            <li style={{ marginBottom: "8px" }}>Circumvent, disable, or disrupt security features of the Service.</li>
            <li style={{ marginBottom: "8px" }}>Engage in conduct that may disparage, tarnish, or otherwise harm LearnOS LLC or the integrity of the Service.</li>
            <li style={{ marginBottom: "8px" }}>Harass, abuse, or incite harm against any individual through the platform.</li>
            <li style={{ marginBottom: "8px" }}>Submit false reports, fraudulent claims, or misuse our support services.</li>
            <li style={{ marginBottom: "8px" }}>Utilize the Service in violation of any applicable law or regulation.</li>
            <li style={{ marginBottom: "8px" }}>Upload or transmit malicious software, excessive spam, or disruptive content.</li>
            <li style={{ marginBottom: "8px" }}>Engage in automated system manipulation, including the use of bots, scrapers, or unauthorized scripts.</li>
            <li style={{ marginBottom: "8px" }}>Impersonate another user or collect personally identifiable information without consent.</li>
            <li style={{ marginBottom: "8px" }}>Employ passive tracking technologies (e.g., spyware, web beacons) for unauthorized data collection.</li>
            <li style={{ marginBottom: "8px" }}>Interfere with the operation, stability, or functionality of the Service or its connected networks.</li>
            <li style={{ marginBottom: "8px" }}>Threaten, intimidate, or target LearnOS LLC employees, contractors, or service representatives.</li>
            <li style={{ marginBottom: "8px" }}>Use the Service for unauthorized commercial activities, including advertisements and solicitations.</li>
          </ul>
          <p style={{ marginBottom: "20px" }}>Users may not post or link to any accounts, content, or services that violate LearnOS LLC policies, including but not limited to adult content, fraudulent schemes, or prohibited activities.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>6. Intellectual Property & Content Removal Requests</h2>
          <p style={{ marginBottom: "10px" }}>If you are a third-party entity seeking the removal of content or material that allegedly infringes upon your intellectual property rights or other owned material, you must contact us and submit a takedown request through email. We will evaluate such requests in accordance with applicable legal frameworks. LearnOS LLC is committed to working collaboratively with third-party entities to resolve such matters and will make reasonable efforts to address concerns before any legal action becomes necessary. However, LearnOS LLC assumes no liability for said material and is not obligated to honor takedown requests that lack a legal basis.</p>
          <p style={{ marginBottom: "10px" }}>All content, software, and intellectual property within the Service remain the proprietary property of LearnOS LLC or its rightful licensors.</p>
          <p style={{ marginBottom: "10px" }}>Unauthorized reproduction, distribution, or exploitation of LearnOS LLC content is strictly prohibited.</p>
          <p style={{ marginBottom: "20px" }}>LearnOS LLC manages copyright infringement claims in compliance with applicable laws and reserves the right to take corrective actions, including content removal or account suspension, at its sole discretion.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>7. Third-Party Services & External Links</h2>
          <p style={{ marginBottom: "10px" }}>Users acknowledge that engagement with third-party services, features, or content accessible via LearnOS LLC is at their own risk.</p>
          <p style={{ marginBottom: "10px" }}>LearnOS LLC makes no representations or warranties regarding the accuracy, security, or reliability of third-party services and assumes no liability for their use.</p>
          <p style={{ marginBottom: "20px" }}>LearnOS LLC is not responsible for any tracking, data collection, or privacy practices employed by third-party entities.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>8. Termination & Appeals</h2>
          <p style={{ marginBottom: "10px" }}>LearnOS LLC may terminate or suspend user access to the Service at its sole discretion in cases of policy violations.</p>
          <p style={{ marginBottom: "10px" }}>Users may initiate account termination at any time by contacting support.</p>
          <p style={{ marginBottom: "10px" }}>Termination of an account may result in the permanent loss of associated data and privileges.</p>
          <p style={{ marginBottom: "20px" }}>Users who believe their account was wrongfully terminated may submit an appeal by contacting LearnOS LLC's support team. Appeals are reviewed at our sole discretion, and we are under no obligation to reinstate access.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>9. Disclaimers & Limitations of Liability</h2>
          <p style={{ marginBottom: "10px" }}>The Service is provided "as is," without warranties of any kind, express or implied.</p>
          <p style={{ marginBottom: "10px" }}>LearnOS LLC is not liable for service interruptions, loss of data, unauthorized account access, or third-party breaches.</p>
          <p style={{ marginBottom: "10px" }}>User-generated content does not reflect the views, opinions, or endorsements of LearnOS LLC. We disclaim all liability arising from user-submitted materials.</p>
          <p style={{ marginBottom: "10px" }}>Our liability is strictly limited to the aggregate amount paid by the user in the preceding six months.</p>
          <p style={{ marginBottom: "20px" }}>LearnOS LLC is not affiliated, associated, authorized, endorsed by, or in any way officially connected with third-party services. All product and company names mentioned and images shown are the registered trademarks of their respective owners.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>10. Indemnification</h2>
          <p style={{ marginBottom: "10px" }}>Users agree to indemnify, defend, and hold harmless LearnOS LLC, its affiliates, officers, and employees from any claims, damages, or expenses arising from their use of the Service or violation of these Terms.</p>
          <p style={{ marginBottom: "10px" }}>LearnOS LLC reserves the right to assume exclusive legal defense in relevant matters, and users agree to cooperate in such cases.</p>
          <p style={{ marginBottom: "20px" }}>Users accept full responsibility for covering legal costs, damages, and liabilities incurred by LearnOS LLC resulting from their violations of these Terms.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>11. Modifications to These Terms</h2>
          <p style={{ marginBottom: "20px" }}>LearnOS LLC reserves the right to modify these Terms at any time. Users are encouraged to review the "Last Updated" date periodically. Continued use of the Service after any modifications constitute acceptance of the updated Terms.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>12. Contact Information</h2>
          <p style={{ marginBottom: "10px" }}>For inquiries regarding these Terms, you may contact us at:</p>
          <div style={{ marginBottom: "40px", padding: "15px 0" }}>
            contact@edutools.app
            <br />
            LearnOS LLC
            <br />
            Wyoming, United States
            <br />
            32 N Gould St
            <br />
            Sheridan, 82801
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsOfService;
