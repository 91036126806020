import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, provider, signInWithPopup } from "../firebase";
import { Typography, Container, Card, Button, Stack } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Navbar from "../components/Navbar";

function Login() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  // Redirect if user is logged in
  useEffect(() => {
    if (user) {
      // Redirect to login success page instead of home
      navigate("/login-success");
    }
  }, [user, navigate]);

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      // After successful login, user will be redirected to login-success by the useEffect
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  if (loading) {
    return (
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <Typography variant="h5" sx={{ mb: 3, color: "white" }}>
          Loading...
        </Typography>
      </Container>
    );
  }

  return (
    <div className="page-wrapper">
      <Navbar />

      <div className="section-hero wf-section" id="hero-section" style={{ minHeight: "100vh", display: "flex", alignItems: "center" }}>
        <div className="container-default w-container" style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
            <Card
              sx={{
                maxWidth: "450px",
                width: "100%",
                background: "radial-gradient(circle at 50% 200%, #34353919, #17181a00 61%), radial-gradient(circle at 50% -60%, #3435394a, #17181a00 75%)",
                backdropFilter: "blur(10px)",
                borderRadius: "16px",
                border: "1px solid rgba(255, 255, 255, 0.102)",
                padding: "32px",
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                color: "white",
                textAlign: "center",
                margin: "0 16px",
              }}
            >
              <Typography
                variant="h4"
                component="h1"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  marginBottom: "16px",
                }}
              >
                Login to EduTools
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  marginBottom: "32px",
                  color: "rgba(255, 255, 255, 0.7)",
                }}
              >
                Welcome. Please sign in with your Google account to continue.
              </Typography>

              <Stack spacing={2} sx={{ mb: 3 }}>
                <Button
                  variant="contained"
                  startIcon={<GoogleIcon />}
                  onClick={handleGoogleSignIn}
                  fullWidth
                  sx={{
                    textTransform: "none",
                    fontSize: "1rem",
                    padding: "12px 20px",
                    color: "#fff",
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    border: "1px solid rgba(255, 255, 255, 0.2)",
                    borderRadius: "8px",
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.15)",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      transform: "translateY(-2px)",
                    },
                  }}
                >
                  Sign in with Google
                </Button>

                <Button
                  variant="outlined"
                  startIcon={<PersonAddIcon />}
                  onClick={handleGoogleSignIn}
                  fullWidth
                  sx={{
                    textTransform: "none",
                    fontSize: "1rem",
                    padding: "12px 20px",
                    color: "#fff",
                    backgroundColor: "transparent",
                    border: "1px solid rgba(255, 255, 255, 0.2)",
                    borderRadius: "8px",
                    boxShadow: "none",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.05)",
                      borderColor: "rgba(255, 255, 255, 0.3)",
                      transform: "translateY(-2px)",
                    },
                  }}
                >
                  Create an account
                </Button>
              </Stack>

              <Typography
                variant="caption"
                display="block"
                sx={{
                  color: "rgba(255, 255, 255, 0.5)",
                  fontSize: "0.75rem",
                }}
              >
                By signing in, you agree to our{" "}
                <Link
                  to="/terms-of-service"
                  style={{
                    color: "rgba(255, 255, 255, 0.7)",
                    textDecoration: "underline",
                  }}
                >
                  Terms
                </Link>{" "}
                and{" "}
                <Link
                  to="/privacy-policy"
                  style={{
                    color: "rgba(255, 255, 255, 0.7)",
                    textDecoration: "underline",
                  }}
                >
                  Privacy Policy
                </Link>
                .
              </Typography>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
