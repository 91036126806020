import React from "react";
import { motion } from "framer-motion";

/**
 * Section that displays trusted organizations and their logos
 */
const TrustedBySection = ({ companyLogos }) => {
  return (
    <motion.div
      style={{
        paddingTop: "40px",
        paddingBottom: "40px",
      }}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: false, margin: "-50px" }}
      transition={{ duration: 0.8, ease: "easeOut" }}
    >
      <div className="fade-in-on-scroll">
        <div
          className="company-logo-holder"
          style={{
            position: "relative",
            maxWidth: "1400px",
            margin: "0 auto",
            overflow: "hidden",
          }}
        >
          <div className="scrolling-logos">
            {/* Duplicate logos for smooth infinite scrolling */}
            {[...Array(20)].map((_, index) => (
              <div className="company-logo-container" key={index}>
                {companyLogos.map((logo, logoIndex) => (
                  <div className="company-logo-wrapper" key={logoIndex}>
                    <img
                      src={logo.src}
                      loading="lazy"
                      alt={logo.alt}
                      className="company-logo"
                      {...(logo.srcSet && { srcSet: logo.srcSet })}
                      {...(logo.sizes && { sizes: logo.sizes })}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
          {/* Gradient overlays */}
          <div
            className="gradient-overlay left"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "150px",
              height: "100%",
              background:
                "linear-gradient(to right, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%)",
              zIndex: 2,
            }}
          />
          <div
            className="gradient-overlay right"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "150px",
              height: "100%",
              background:
                "linear-gradient(to left, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%)",
              zIndex: 2,
            }}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default TrustedBySection;
