import React from 'react';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import PlatformIcon from './PlatformIcon';

/**
 * Section that displays cross-platform support information
 */
const CrossPlatformSection = () => {
  return (
    <div id="cross-platform-section" className="section overflow-hidden">
      <div className="container">
        <div className="section-center-text">
          <div className="section-title">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false }}
              transition={{ duration: 0.7, ease: "easeOut" }}
            >
              <h2 className="title medium">
                <strong>Cross-Platform Support</strong>
              </h2>
            </motion.div>
          </div>
          <div className="section-paragraph">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false }}
              transition={{ duration: 0.7, ease: "easeOut", delay: 0.2 }}
            >
              <p>
                Seamlessly access your tools and workspaces on any device.
              </p>
            </motion.div>
          </div>
        </div>
        <div
          className="w-layout-grid ease-features-grid"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: {
                xs: 4,
                sm: 8,
              },
              width: "100%",
              mt: 1,
            }}
          >
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: false, margin: "-50px" }}
              transition={{ duration: 0.5 }}
              whileHover={{ y: -10, transition: { duration: 0.2 } }}
            >
              <PlatformIcon 
                icon="./images/apple.png" 
                label="Mac" 
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: false, margin: "-50px" }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ y: -10, transition: { duration: 0.2 } }}
            >
              <PlatformIcon 
                icon="./images/window.png" 
                label="Windows" 
              />
            </motion.div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default CrossPlatformSection; 