import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import AOS from "aos";
import "aos/dist/aos.css";

// Import CSS files
import "../styles/home/scrollbar.css";
import "../styles/home/animations.css";
import "../styles/home/hero.css";
import "../styles/home/layout.css";
import "../styles/home/components.css";
// import "../styles/home/valueProposition.css";

// Import components from index file
import {
  HeroSection,
  TrustedBySection,
  TestimonialsSection,
  CrossPlatformSection,
  CallToActionSection,
  PricingSection,
  FAQSection,
  FooterSection,
  ValuePropositionSection,
} from "../components/home";

const Home = () => {
  const [openIndexes, setOpenIndexes] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Toggle FAQ handler
  const toggleFAQ = (index) => {
    setOpenIndexes((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  // Smooth scroll to next section
  const scrollToNextSection = () => {
    const nextSection = document.getElementById("trusted-by-id");
    if (nextSection) {
      // Add a class to enable the slower transition
      document.documentElement.classList.add("scrolling-active");

      // Calculate the position to scroll to
      const navbarHeight = 80; // Adjust based on your navbar height
      const elementPosition =
        nextSection.getBoundingClientRect().top +
        window.pageYOffset -
        navbarHeight;

      // Perform the smooth scroll with a longer duration
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });

      // Remove the class after the animation completes
      setTimeout(() => {
        document.documentElement.classList.remove("scrolling-active");
      }, 2500); // Match this with the transition duration in the CSS (2.5s)
    }
  };

  // Data for company logos
  const companyLogos = [
    {
      src: "./images/wake-forest.png",
      alt: "Wake Forest",
      srcSet: "",
      sizes: "",
    },
    {
      src: "./images/usc.png",
      alt: "USC",
      srcSet: "",
      sizes: "",
    },
    {
      src: "./images/ucsd.png",
      alt: "UCSD",
      srcSet: "",
      sizes: "",
    },
    {
      src: "./images/stanford.png",
      alt: "Stanford",
      srcSet: "",
      sizes: "",
    },
    {
      src: "./images/ucla.png",
      alt: "UCLA",
      srcSet:
        "./images/ucla-p-500.png 500w, ./images/ucla-p-800.png 800w, ./images/ucla.png 1045w",
      sizes: "(max-width: 1439px) 100vw, 3200.3125px",
    },
    {
      src: "./images/berkeley.png",
      alt: "Berkeley",
      srcSet: "",
      sizes: "",
    },
    {
      src: "./images/nyu.png",
      alt: "NYU",
      srcSet: "",
      sizes: "",
    },
    {
      src: "./images/purdue.png",
      alt: "Purdue",
      srcSet:
        "./images/purdue-p-500.png 500w, ./images/purdue-p-800.png 800w, ./images/purdue.png 918w",
      sizes:
        "(max-width: 479px) 64.63542175292969px, (max-width: 767px) 13vw, 98.48958587646484px",
    },
  ];

  // Testimonials Data Array
  const testimonials = [
    {
      id: 1,
      userImage: {
        src: "./images/LoganGubser.jpg",
        alt: "Logan Gubser",
        srcSet:
          "./images/LoganGubser-p-500.jpg 500w, ./images/LoganGubser.jpg 621w",
        sizes: "(max-width: 479px) 25vw, 60px",
      },
      userName: "Logan Gupser",
      userPosition: "UW-Madison",
      companyLogo: {
        src: "./images/Wisconsin_Badgers_logo.svg.png",
        alt: "Wisconsin Badgers",
        srcSet:
          "./images/Wisconsin_Badgers_logo.svg-p-500.png 500w, ./images/Wisconsin_Badgers_logo.svg.png 640w",
        sizes: "45px",
        width: 45,
      },
      testimonialText: "If you don't have Edutools, what are you doing?",
    },
    {
      id: 2,
      userImage: {
        src: "./images/MiaChen.png",
        alt: "Mia Chen",
        srcSet:
          "./images/MiaChen-p-500.png 500w, ./images/MiaChen-p-800.png 800w, ./images/MiaChen.png 1080w",
        sizes: "60px",
      },
      userName: "Armen Sam",
      userPosition: "Purdue University",
      companyLogo: {
        src: "./images/MIT_logo.svg.png",
        alt: "Purdue Logo",
        srcSet:
          "./images/MIT_logo.svg-p-500.png 500w, ./images/MIT_logo.svg-p-800.png 800w, ./images/MIT_logo.svg-p-1080.png 1080w, ./images/MIT_logo.svg-p-1600.png 1600w, ./images/MIT_logo.svg-p-2000.png 2000w, ./images/MIT_logo.svg.png 2560w",
        sizes: "60px",
        width: 60,
      },
      testimonialText: "THE all-in-one solution that every student needs.",
    },
    {
      id: 3,
      userImage: {
        src: "./images/JasmineH.jpg",
        alt: "Jasmine H.",
        srcSet:
          "./images/JasmineH-p-500.jpg 500w, ./images/JasmineH-p-800.jpg 800w, ./images/JasmineH.jpg 1080w",
        sizes: "60px",
      },
      userName: "Jasmine H.",
      userPosition: "Stevenson High",
      companyLogo: {
        src: "./images/SHS_S__005.webp",
        alt: "Stevenson High School Logo",
        srcSet:
          "./images/SHS_S__005-p-500.webp 500w, ./images/SHS_S__005-p-800.webp 800w, ./images/SHS_S__005.webp 860w",
        sizes: "50px",
        width: 50,
      },
      testimonialText: "This is what you need to succeed as a student.",
    },
    {
      id: 4,
      userImage: {
        src: "./images/AidenS.png",
        alt: "Aiden Sheena",
        srcSet:
          "./images/AidenS-p-500.png 500w, ./images/AidenS-p-800.png 800w, ./images/AidenS.png 898w",
        sizes: "60px",
      },
      userName: "Aiden Sheena",
      userPosition: "San Diego State University",
      companyLogo: {
        src: "./images/800x600-athletics-logo.png",
        alt: "Athletics Logo",
        srcSet:
          "./images/800x600-athletics-logo-p-500.png 500w, ./images/800x600-athletics-logo.png 800w",
        sizes: "45px",
        width: 45,
      },
      testimonialText:
        "Edutools has saved me so much time, allowing me to focus on other important areas of my life.",
    },
  ];

  // Pricing Plans Data
  const pricingPlans = [
    {
      id: 1,
      plan: "Free",
      price: "$0",
      button: {
        href: "#",
        text: "Current Plan",
        className: "button third w-button",
        style: {
          pointerEvents: "none",
          opacity: 0.5,
        },
      },
      features: [
        "Basic study resources",
        "Access to a network of like-minded and drivenstudents",
        "Additional free features and toolsto be announced",
      ],
      isCurrent: true,
    },
    {
      id: 2,
      plan: "Monthly",
      billingType: "Billed monthly.",
      price: "$19.99",
      button: {
        href: "./download-app",
        text: "Get started",
        className: "button secondary w-button",
        style: {},
      },
      features: [
        "Access to all basic features",
        "Access all your favorite premium tools",
        "High speed desktop app",
        "24/7 priority chat support",
        "New tool suggestion requests",
      ],
      isCurrent: false,
    },
    {
      id: 3,
      plan: "Semesterly",
      billingType: "Billed per semester (4 months).",
      price: "$49.99",
      originalPrice: "$79.99",
      button: {
        href: "./download-app",
        text: "Get started",
        className: "button secondary w-button",
        style: {},
      },
      features: [
        "$30 off",
        "Access to all monthly features",
        "Special perks and features to be announced",
      ],
      isCurrent: false,
    },
  ];

  // FAQ Data
  const faqData = [
    {
      question: "Why choose EduTools?",
      answer:
        "EduTools is the ultimate all-in-one subscription for students. It saves you time, money, and effort while providing everything you need to excel academically. With our premium suite of tools, we can ensure you never have to buy another study subscription again.",
      icon: "./images/Purple-Plus.svg",
    },
    {
      question: "How can I create an account?",
      answer:
        "Creating an account is quick and easy! Simply sign in with your Google account to get started and explore all the tools EduTools has to offer.",
      icon: "./images/Purple-Plus.svg",
    },
    {
      question: "Does EduTools consider suggestions for new features?",
      answer:
        "Absolutely! We value input from our community and pride ourselves on improving and adding new tools to the platform. Share your suggestions, and we'll strive to bring them to our platform.",
      icon: "./images/Purple-Plus.svg",
    },
    {
      question: "How can I contact EduTools?",
      answer:
        'Our support team is available 24/7 to assist with your questions or concerns. Reach out to us through the in-app support chat, instagram, or email us at <a href="mailto:contact@edutools.app">contact@edutools.app</a>.',
      icon: "./images/Purple-Plus.svg",
    },
  ];

  // Add responsive width handler
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial call

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Initialize AOS animation library
  useEffect(() => {
    // Initialize AOS with proper settings
    AOS.init({
      disable: false, // Enable animations
      once: false, // Allow animations to occur every time element scrolls into view
      duration: 800, // Animation duration in milliseconds
      delay: 50, // Small delay for staggered animations
      easing: "ease-out-cubic", // Smooth easing
      mirror: false, // No reverse animation when scrolling up
      anchorPlacement: "top-bottom", // Defines which position of the element regarding to window should trigger the animation
    });

    // Refresh AOS whenever window is resized
    window.addEventListener("resize", () => {
      AOS.refresh();
    });

    return () => {
      window.removeEventListener("resize", () => {
        AOS.refresh();
      });
    };
  }, []);

  return (
    <>
      <Navbar />
      <HeroSection />
      <TrustedBySection companyLogos={companyLogos} />
      <ValuePropositionSection />
      <TestimonialsSection testimonials={testimonials} />
      <CrossPlatformSection />
      <CallToActionSection />
      <PricingSection pricingPlans={pricingPlans} />
      <FAQSection
        faqData={faqData}
        openIndexes={openIndexes}
        toggleFAQ={toggleFAQ}
      />
      <FooterSection />
    </>
  );
};

export default Home;
