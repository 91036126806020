import React from 'react';
import { motion } from 'framer-motion';

/**
 * A floating UI element component that displays a logo with animation effects
 */
const FloatingUIElement = ({ 
  position, 
  logo, 
  logoAlt, 
  logoSize = "60%", 
  size = "80px", 
  color = "rgba(0, 124, 255, 0)",
  animationDuration = 7,
  animationOffset = { y: [0, 15, 0], rotateX: [-5, -10, -5], rotateY: [8, 12, 8] },
  borderColor = "rgba(78, 78, 78, 0.25)"
}) => {
  return (
    <motion.div
      className="floating-ui-element"
      style={{
        position: "absolute",
        ...position, // This will spread top/bottom/left/right values
        background: "rgba(20, 20, 25, 0.3)",
        borderRadius: "16px",
        border: `2px solid ${borderColor}`,
        width: size,
        height: size,
        zIndex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: `0 10px 25px -5px rgba(0, 0, 0, 0.5), inset 0 -20px 20px -5px ${borderColor}`,
        transform: `perspective(800px) rotateX(${animationOffset.rotateX[0]}deg) rotateY(${animationOffset.rotateY[0]}deg)`,
      }}
      animate={{
        y: animationOffset.y,
        rotateX: animationOffset.rotateX,
        rotateY: animationOffset.rotateY,
      }}
      transition={{
        duration: animationDuration,
        repeat: Infinity,
        repeatType: "reverse",
        ease: "easeInOut",
      }}
    >
      <img src={require(`../../assets/logos/${logo}`)} alt={logoAlt} style={{ width: logoSize, height: "auto" }} />
    </motion.div>
  );
};

export default FloatingUIElement; 