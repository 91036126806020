import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import logoImage from "../images/logo_1.png"; // Adjust the path as necessary
import { AppBar, Toolbar, Typography, Box, Button } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

function Navbar() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      console.log("User signed out");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  // Add a custom scroll function for smooth scrolling
  const scrollWithCallback = (el) => {
    const elementPosition = el.offsetTop;
    window.scrollTo({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };

  // Add a function to scroll to the top of the page
  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        background: "transparent",
        backdropFilter: "blur(8px)",
        boxShadow: "none",
      }}
    >
      <Toolbar>
        <Box
          sx={{
            width: "100%",
            maxWidth: "1200px",
            margin: "0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Link
              to="/"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                textDecoration: "none",
              }}
            >
              <img
                src={logoImage}
                alt="Edutools Logo"
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontWeight: 600,
                  color: "white",
                }}
              >
                EduTools
              </Typography>
            </Link>
          </Box>

          <Box
            className="nav-links-custom-holder"
            sx={{
              display: "flex",
              gap: "2rem",
            }}
          >
            <Link to="/" className="nav-link">
              Home
            </Link>
            <HashLink
              to="/#trusted-by-id"
              className="nav-link"
              scroll={scrollWithCallback}
            >
              Features
            </HashLink>
            <HashLink
              to="/#review-section"
              className="nav-link"
              scroll={scrollWithCallback}
            >
              Reviews
            </HashLink>
            <HashLink
              to="/#pricing-section"
              className="nav-link"
              scroll={scrollWithCallback}
            >
              Pricing
            </HashLink>
            <HashLink
              to="/#faq-section"
              className="nav-link"
              scroll={scrollWithCallback}
            >
              FAQ
            </HashLink>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "0.75rem" }}>
            <div className="nav-button-holder">
              <Link to="/download-app" className="button" id="auth-button">
                Download
              </Link>
            </div>

            {!loading && user && (
              <Button
                variant="outlined"
                size="small"
                onClick={handleSignOut}
                startIcon={<LogoutIcon />}
                sx={{
                  color: "white",
                  borderColor: "rgba(255, 255, 255, 0.2)",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                    borderColor: "rgba(255, 255, 255, 0.3)",
                  },
                  textTransform: "none",
                }}
              >
                Logout
              </Button>
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
