import React from 'react';
import { Link } from 'react-router-dom';
import logoImage from '../../images/logo_1.png';

/**
 * Footer section that appears at the bottom of every page
 * Now optimized for mobile devices
 */
const FooterSection = () => {
  // Check if viewport is mobile
  const isMobile = window.innerWidth <= 768;

  return (
    <div
      className="section"
      style={{
        background:
          "radial-gradient(circle at 50% 200%, #34353930, #17181a00 61%), radial-gradient(circle at 50% -60%, #3435394a, #17181a00 75%)",
      }}
    >
      <div className={isMobile ? "" : "container"} style={{ 
        padding: isMobile ? "40px 20px 20px" : "60px 20px 30px" 
      }}>
        <div
          className="footer-wrapper"
          style={{
            display: "grid",
            gridTemplateColumns: isMobile ? "1fr" : "repeat(auto-fit, minmax(250px, 1fr))",
            gap: isMobile ? "30px" : "40px",
            marginBottom: isMobile ? "30px" : "40px",
          }}
        >
          {/* Brand Section */}
          <div className="footer-brand-section">
            <a
              href="/"
              className="footer-brand w-inline-block"
              style={{
                marginBottom: "15px",
                display: "inline-flex",
                alignItems: "center",
                textDecoration: "none",
              }}
            >
              <img
                src={logoImage}
                loading="lazy"
                width="36"
                height="36"
                alt="Edutools Logo"
                className="brand-image"
                style={{ marginRight: "10px" }}
              />
              <h4
                style={{
                  color: "#fff",
                  margin: 0,
                  fontSize: isMobile ? "22px" : "24px",
                  fontWeight: "600",
                }}
              >
                Edutools
              </h4>
            </a>
            <p
              style={{
                color: "rgba(255, 255, 255, 0.7)",
                fontSize: "14px",
                lineHeight: "1.6",
                marginBottom: "15px",
                maxWidth: "300px",
              }}
            >
              Become an Academic Weapon with the ultimate all-in-one
              subscription for students.
            </p>
            <div
              className="social-links"
              style={{ display: "flex", gap: "16px", marginTop: "15px" }}
            >
              <a
                href="https://www.instagram.com/edutoo1s/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
                style={{
                  color: "rgba(255, 255, 255, 0.7)",
                  transition: "color 0.3s ease",
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zM12 0C8.741 0 8.333.014 7.053.072 2.695.272.273 2.69.073 7.052.014 8.333 0 8.741 0 12c0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98C8.333 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98C15.668.014 15.259 0 12 0zm0 5.838a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324zM12 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm6.406-11.845a1.44 1.44 0 1 0 0 2.881 1.44 1.44 0 0 0 0-2.881z" />
                </svg>
              </a>
            </div>
          </div>

          {/* Mobile optimized layout for the link sections */}
          <div 
            className="footer-links-mobile-wrapper"
            style={{
              display: isMobile ? "grid" : "none",
              gridTemplateColumns: "1fr 1fr",
              gap: "20px"
            }}
          >
            {/* Quick Links Section */}
            <div className="footer-links-section">
              <h5
                style={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "600",
                  marginBottom: "15px",
                }}
              >
                Quick Links
              </h5>
              <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/"
                    style={{
                      color: "rgba(255, 255, 255, 0.7)",
                      textDecoration: "none",
                      transition: "color 0.3s ease",
                      fontSize: "14px",
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/features"
                    style={{
                      color: "rgba(255, 255, 255, 0.7)",
                      textDecoration: "none",
                      transition: "color 0.3s ease",
                      fontSize: "14px",
                    }}
                  >
                    Features
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/download-app"
                    style={{
                      color: "rgba(255, 255, 255, 0.7)",
                      textDecoration: "none",
                      transition: "color 0.3s ease",
                      fontSize: "14px",
                    }}
                  >
                    Download
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/pricing"
                    style={{
                      color: "rgba(255, 255, 255, 0.7)",
                      textDecoration: "none",
                      transition: "color 0.3s ease",
                      fontSize: "14px",
                    }}
                  >
                    Pricing
                  </Link>
                </li>
              </ul>
            </div>

            {/* Legal Section */}
            <div className="footer-legal-section">
              <h5
                style={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "600",
                  marginBottom: "15px",
                }}
              >
                Legal
              </h5>
              <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/terms-of-service"
                    style={{
                      color: "rgba(255, 255, 255, 0.7)",
                      textDecoration: "none",
                      transition: "color 0.3s ease",
                      fontSize: "14px",
                    }}
                  >
                    Terms of Service
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/privacy-policy"
                    style={{
                      color: "rgba(255, 255, 255, 0.7)",
                      textDecoration: "none",
                      transition: "color 0.3s ease",
                      fontSize: "14px",
                    }}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link
                    to="/cookies-policy"
                    style={{
                      color: "rgba(255, 255, 255, 0.7)",
                      textDecoration: "none",
                      transition: "color 0.3s ease",
                      fontSize: "14px",
                    }}
                  >
                    Cookies Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Desktop only sections */}
          {!isMobile && (
            <>
              {/* Links Section */}
              <div className="footer-links-section">
                <h5
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "600",
                    marginBottom: "20px",
                  }}
                >
                  Quick Links
                </h5>
                <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                  <li style={{ marginBottom: "12px" }}>
                    <Link
                      to="/"
                      style={{
                        color: "rgba(255, 255, 255, 0.7)",
                        textDecoration: "none",
                        transition: "color 0.3s ease",
                        fontSize: "14px",
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    <Link
                      to="/features"
                      style={{
                        color: "rgba(255, 255, 255, 0.7)",
                        textDecoration: "none",
                        transition: "color 0.3s ease",
                        fontSize: "14px",
                      }}
                    >
                      Features
                    </Link>
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    <Link
                      to="/download-app"
                      style={{
                        color: "rgba(255, 255, 255, 0.7)",
                        textDecoration: "none",
                        transition: "color 0.3s ease",
                        fontSize: "14px",
                      }}
                    >
                      Download
                    </Link>
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    <Link
                      to="/pricing"
                      style={{
                        color: "rgba(255, 255, 255, 0.7)",
                        textDecoration: "none",
                        transition: "color 0.3s ease",
                        fontSize: "14px",
                      }}
                    >
                      Pricing
                    </Link>
                  </li>
                </ul>
              </div>

              {/* Legal Section */}
              <div className="footer-legal-section">
                <h5
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "600",
                    marginBottom: "20px",
                  }}
                >
                  Legal
                </h5>
                <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                  <li style={{ marginBottom: "12px" }}>
                    <Link
                      to="/terms-of-service"
                      style={{
                        color: "rgba(255, 255, 255, 0.7)",
                        textDecoration: "none",
                        transition: "color 0.3s ease",
                        fontSize: "14px",
                      }}
                    >
                      Terms of Service
                    </Link>
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    <Link
                      to="/privacy-policy"
                      style={{
                        color: "rgba(255, 255, 255, 0.7)",
                        textDecoration: "none",
                        transition: "color 0.3s ease",
                        fontSize: "14px",
                      }}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    <Link
                      to="/cookies-policy"
                      style={{
                        color: "rgba(255, 255, 255, 0.7)",
                        textDecoration: "none",
                        transition: "color 0.3s ease",
                        fontSize: "14px",
                      }}
                    >
                      Cookies Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </>
          )}

          {/* Contact Section */}
          <div className="footer-contact-section">
            <h5
              style={{
                color: "#fff",
                fontSize: isMobile ? "16px" : "18px",
                fontWeight: "600",
                marginBottom: isMobile ? "15px" : "20px",
              }}
            >
              Contact Us
            </h5>
            <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
              <li
                style={{
                  marginBottom: isMobile ? "12px" : "16px",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    minWidth: "18px",
                    marginRight: "10px",
                    marginTop: "3px",
                    color: "rgba(255, 255, 255, 0.7)",
                  }}
                >
                  <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
                </svg>
                <a
                  href="mailto:contact@edutools.app"
                  style={{
                    color: "rgba(255, 255, 255, 0.7)",
                    textDecoration: "none",
                    transition: "color 0.3s ease",
                    fontSize: "14px",
                  }}
                >
                  contact@edutools.app
                </a>
              </li>
              <li
                style={{
                  marginBottom: isMobile ? "12px" : "16px",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    minWidth: "18px",
                    marginRight: "10px",
                    marginTop: "3px",
                    color: "rgba(255, 255, 255, 0.7)",
                  }}
                >
                  <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z" />
                </svg>
                <span
                  style={{
                    color: "rgba(255, 255, 255, 0.7)",
                    fontSize: "14px",
                  }}
                >
                  LearnOS LLC, United States
                </span>
              </li>
            </ul>
          </div>
        </div>

        {/* Divider */}
        <div
          className="footer-divider"
          style={{
            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
            paddingTop: isMobile ? "15px" : "20px",
            marginTop: isMobile ? "15px" : "20px",
          }}
        >
          <div
            className="footer-copyright-holder"
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: isMobile ? "flex-start" : "center",
              gap: isMobile ? "15px" : "20px",
            }}
          >
            <div
              className="footer-copyright"
              style={{ 
                color: "rgba(255, 255, 255, 0.5)", 
                fontSize: "14px",
                textAlign: isMobile ? "center" : "left",
                width: isMobile ? "100%" : "auto"
              }}
            >
              © {new Date().getFullYear()} LearnOS LLC. All rights reserved.
            </div>
            <div
              className="footer-meta-links"
              style={{ 
                display: "flex", 
                gap: "20px",
                justifyContent: isMobile ? "center" : "flex-end",
                width: isMobile ? "100%" : "auto"
              }}
            >
              <a
                href="#"
                style={{
                  color: "rgba(255, 255, 255, 0.5)",
                  textDecoration: "none",
                  fontSize: "14px",
                  transition: "color 0.3s ease",
                }}
              >
                Sitemap
              </a>
              <a
                href="#"
                style={{
                  color: "rgba(255, 255, 255, 0.5)",
                  textDecoration: "none",
                  fontSize: "14px",
                  transition: "color 0.3s ease",
                }}
              >
                Accessibility
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSection; 