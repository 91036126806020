import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import {
  Typography,
  Container,
  Card,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import LaunchIcon from "@mui/icons-material/Launch";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

function LoginSuccess() {
  const [user, loading] = useAuthState(auth);
  const [token, setToken] = useState("");
  const [creatingUser, setCreatingUser] = useState(false);
  const navigate = useNavigate();
  const iframeRef = useRef(null);

  useEffect(() => {
    // If no user is logged in, redirect to login page
    if (!loading && !user) {
      navigate("/login");
      return;
    }

    // If user is logged in, check if they exist in Firestore and get the token
    if (user) {
      const getUserDataAndToken = async () => {
        try {
          setCreatingUser(true);

          // Check if user exists in Firestore
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);

          // If user doesn't exist, create a new user document
          if (!userDoc.exists()) {
            const newUser = {
              blocks: [],
              dateJoined: new Date().toISOString(),
              displayName: user.displayName || user.email,
              email: user.email,
              friendRequests: [],
              friends: [],
              photoURL: user.photoURL || "",
              tags: [],
              paymentStatus: "free",
              socials: {
                discord: "",
                instagram: "",
                snapchat: "",
              },
            };

            await setDoc(userDocRef, newUser);
          } else {
            // Existing user found in Firestore
          }

          // Get the Firebase Cloud Functions instance
          const functions = getFunctions();

          // Generate custom token using the deployed function
          try {
            // Call the deployed function
            console.log("Calling generateCustomToken");
            const generateCustomToken = httpsCallable(functions, "generateCustomToken");
            console.log("generateCustomToken", generateCustomToken);
            const result = await generateCustomToken({ uid: user.uid });
            console.log("result", result);

            // Extract the customToken from the result
            const customToken = result.data.customToken;
            setToken(customToken);

            // Immediately try to open app once we have the custom token
            setTimeout(() => {
              openApp(customToken);
            }, 500); // Short timeout to ensure UI renders first
          } catch (tokenError) {
            console.error("Error generating custom token:", tokenError);
            // Fallback to ID token if custom token generation fails
            const idToken = await user.getIdToken();
            setToken(idToken);

            setTimeout(() => {
              openApp(idToken);
            }, 500);
          }

          setCreatingUser(false);
        } catch (error) {
          console.error("Error in account setup:", error);
          setCreatingUser(false);
        }
      };

      getUserDataAndToken();
    }
  }, [user, loading, navigate]);

  const openApp = (idToken) => {
    const tokenToUse = idToken || token;
    const appUrl = `edutools://auth?token=${encodeURIComponent(tokenToUse)}`;

    // Try to open using a hidden anchor tag to potentially reduce browser logging
    try {
      // Create hidden link and trigger click
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = appUrl;
      document.body.appendChild(link);
      link.click();

      // Clean up
      setTimeout(() => {
        document.body.removeChild(link);
      }, 100);
    } catch (e) {
      // Fallback to traditional method if needed
      window.location.href = appUrl;
    }
  };

  if (loading || creatingUser || !token) {
    return (
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress color="primary" />
        <Typography variant="h6" sx={{ mt: 2, color: "white" }}>
          {creatingUser
            ? "Setting up your account..."
            : "Preparing your login..."}
        </Typography>
      </Container>
    );
  }

  return (
    <div className="page-wrapper">
      <Navbar />

      <div
        className="section-hero wf-section"
        id="hero-section"
        style={{ minHeight: "100vh", display: "flex", alignItems: "center" }}
      >
        <div
          className="container-default w-container"
          style={{ width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Card
              sx={{
                maxWidth: "500px",
                width: "100%",
                background:
                  "radial-gradient(circle at 50% 200%, #34353919, #17181a00 61%), radial-gradient(circle at 50% -60%, #3435394a, #17181a00 75%)",
                backdropFilter: "blur(10px)",
                borderRadius: "16px",
                border: "1px solid rgba(255, 255, 255, 0.102)",
                padding: "40px",
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                color: "white",
                textAlign: "center",
                margin: "0 16px",
              }}
            >
              <Typography
                variant="h4"
                component="h1"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  marginBottom: "16px",
                }}
              >
                Login Successful
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  marginBottom: "32px",
                  color: "rgba(255, 255, 255, 0.9)",
                  fontSize: "1.1rem",
                }}
              >
                Opening the EduTools app...
              </Typography>

              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: "rgba(255, 255, 255, 0.8)",
                    fontSize: "0.95rem",
                    mb: 2,
                  }}
                >
                  If the app doesn't open automatically, your browser might have
                  blocked the popup.
                </Typography>

                <Button
                  variant="contained"
                  startIcon={<LaunchIcon />}
                  onClick={() => openApp()}
                  sx={{
                    textTransform: "none",
                    fontSize: "1.1rem",
                    padding: "12px 24px",
                    borderRadius: "8px",
                    background:
                      "linear-gradient(135deg, rgba(0, 153, 255, 0.2), rgba(0, 102, 255, 0.2))",
                    backdropFilter: "blur(10px)",
                    border: "1px solid rgba(255, 255, 255, 0.2)",
                    boxShadow: "0 8px 32px 0 rgba(0, 102, 255, 0.15)",
                    color: "#fff",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      transform: "translateY(-2px)",
                      boxShadow: "0 12px 40px 0 rgba(0, 102, 255, 0.25)",
                      background:
                        "linear-gradient(135deg, rgba(0, 153, 255, 0.3), rgba(0, 102, 255, 0.3))",
                    },
                  }}
                >
                  Open EduTools Now
                </Button>

                <Typography
                  variant="body2"
                  sx={{
                    mt: 3,
                    color: "rgba(255, 255, 255, 0.7)",
                    fontSize: "0.9rem",
                  }}
                >
                  If clicking the button doesn't work, please make sure you have
                  the EduTools app{" "}
                  <Link
                    to="/download-app"
                    style={{
                      color: "rgba(77, 171, 245, 0.9)",
                      textDecoration: "underline",
                      transition: "color 0.2s ease",
                    }}
                  >
                    installed
                  </Link>{" "}
                  on your device.
                </Typography>
              </Box>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginSuccess;
