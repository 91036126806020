import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FloatingUIElement from "./FloatingUIElement";

/**
 * Hero section for the home page
 */
const HeroSection = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [count, setCount] = useState(0);
  const countRef = useRef(null);

  // Check if device is mobile
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Check on initial render
    checkIfMobile();

    // Add event listener for window resize
    window.addEventListener("resize", checkIfMobile);

    // Cleanup
    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  // Add counter animation
  useEffect(() => {
    const targetCount = 600;
    const duration = 2000; // 2 seconds for the animation
    const startTime = Date.now();

    const animateCount = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;

      if (elapsed < duration) {
        // Calculate the eased progress (starts slow, accelerates in the middle, slows at the end)
        const progress = elapsed / duration;
        const easedProgress =
          progress < 0.5
            ? 2 * progress * progress
            : 1 - Math.pow(-2 * progress + 2, 2) / 2;

        // Calculate current count based on progress
        const currentCount = Math.floor(easedProgress * targetCount);
        setCount(currentCount);

        countRef.current = requestAnimationFrame(animateCount);
      } else {
        setCount(targetCount);
      }
    };

    countRef.current = requestAnimationFrame(animateCount);

    // Cleanup animation frame on unmount
    return () => {
      if (countRef.current) {
        cancelAnimationFrame(countRef.current);
      }
    };
  }, []);

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.1,
      },
    },
  };

  return (
    <motion.div
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: 0.8,
        ease: [0.22, 1, 0.36, 1], // Custom cubic-bezier for smooth slide
      }}
    >
      <div
        style={{
          background: "#000",
          position: "relative",
          width: "100%",
          padding: isMobile ? "0 8px" : "0 5%",
        }}
      >
        {/* Background with subtle gradient */}
        <div
          className="hero-background"
          style={{
            margin: isMobile ? "0 10px" : "0 auto",
            width: isMobile ? "calc(100% - 20px)" : "auto",
            borderRadius: isMobile ? "0px 0px 20px 20px" : "0px 0px 25px 25px",
          }}
        >
          {/* Base noise layer - adjusted for visibility */}
          <div className="noise-base" />

          {/* Background elements */}
          {!isMobile && (
            <div className="hero-background-elements">
              {/* Floating UI elements with logos - with unique bobbing animations */}
              <FloatingUIElement
                position={{ top: "15%", left: "10%" }}
                logo="chatgpt.png"
                logoAlt="ChatGPT"
                size="80px"
                logoSize="60%"
                animationDuration={4}
                animationOffset={{
                  y: [0, 15, 0],
                  rotateX: [0, 0, 0],
                  rotateY: [0, 0, 0],
                }}
              />

              <FloatingUIElement
                position={{ bottom: "25%", left: "15%" }}
                logo="quizlet.png"
                logoAlt="Quizlet"
                size="90px"
                logoSize="65%"
                animationDuration={5.5}
                animationOffset={{
                  y: [0, 12, 0],
                  rotateX: [0, 0, 0],
                  rotateY: [0, 0, 0],
                }}
              />

              <FloatingUIElement
                position={{ top: "20%", right: "15%" }}
                logo="grammarly.png"
                logoAlt="Grammarly"
                size="100px"
                logoSize="70%"
                animationDuration={4.8}
                animationOffset={{
                  y: [0, 18, 0],
                  rotateX: [0, 0, 0],
                  rotateY: [0, 0, 0],
                }}
              />

              <FloatingUIElement
                position={{ bottom: "20%", right: "10%" }}
                logo="chegg.png"
                logoAlt="Chegg"
                size="70px"
                logoSize="60%"
                animationDuration={6.2}
                animationOffset={{
                  y: [0, 10, 0],
                  rotateX: [0, 0, 0],
                  rotateY: [0, 0, 0],
                }}
              />
            </div>
          )}

          <div
            className="stripe-hero-container"
            style={{
              position: "relative",
              zIndex: 5,
              padding: isMobile ? "28px 3% 13px" : "42px 5% 38px",
              maxWidth: "1200px",
              margin: "0 auto",
            }}
          >
            {/* Left side - Text content */}
            <motion.div
              className="stripe-hero-content"
              initial="hidden"
              animate="visible"
              variants={staggerContainer}
            >
              {/* Add badge showing user count - updated to be smaller and blue */}
              <motion.div
                variants={fadeInUp}
                className="users-badge"
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  background: "rgba(0, 124, 255, 0.08)",
                  border: "1px solid rgba(0, 124, 255, 0.25)",
                  borderRadius: "100px",
                  padding: "6px 12px",
                  marginBottom: "4px",
                  maxWidth: "fit-content",
                }}
              >
                <div
                  style={{
                    marginRight: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                      stroke="#007CFF"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17 2.44V12.42C17 14.39 15.59 15.16 13.86 14.12L12.54 13.33C12.24 13.15 11.76 13.15 11.46 13.33L10.14 14.12C8.41 15.15 7 14.39 7 12.42V2.44"
                      stroke="#007CFF"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <span
                  style={{
                    fontWeight: 500,
                    color: "white",
                    fontSize: isMobile ? "0.8rem" : "0.9rem",
                  }}
                >
                  Used by{" "}
                  <span style={{ color: "#007CFF", fontWeight: 600 }}>
                    {count}+ Students
                  </span>
                </span>
              </motion.div>

              <motion.h1 variants={fadeInUp} className="stripe-hero-title">
                <span className="title-line">One App to</span>
                <span
                  className="title-line"
                  style={{
                    background:
                      "linear-gradient(to bottom, #01D6FD 0%, #007CFF 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                    display: "inline-block",
                    position: "relative",
                  }}
                >
                  Replace Them All.
                </span>
              </motion.h1>

              <motion.p
                variants={fadeInUp}
                className="stripe-hero-description"
                style={{ fontSize: "1.1rem" }}
              >
                Stop wasting your money on overpriced study subscriptions that
                limit you to only one tool. With our all-in-one suite of tools,
                you can finally make the most of your time and resources.
              </motion.p>

              {/* CTA Buttons */}
              <motion.div
                variants={fadeInUp}
                className="stripe-hero-cta"
                style={{
                  display: "flex",
                  flexDirection: "row", // Keep buttons in a row even on mobile
                  flexWrap: isMobile ? "nowrap" : "wrap", // Prevent wrapping on mobile
                  gap: isMobile ? "10px" : "15px", // Reduce gap between buttons on mobile
                }}
              >
                <motion.div
                  className="button-hover"
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.98 }}
                  style={{
                    flex: isMobile ? "1" : "initial", // Make buttons take equal space on mobile
                    minWidth: isMobile ? "auto" : "initial",
                  }}
                >
                  <Link
                    to="/download-app"
                    className="button"
                    style={{
                      padding: isMobile ? "8px 12px" : "10px 16px",
                      fontSize: isMobile ? "0.9rem" : "0.95rem",
                      width: isMobile ? "100%" : "auto",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Get Started{" "}
                    <RocketLaunchIcon
                      sx={{
                        ml: 1,
                        fontSize: isMobile ? "1rem" : "1.2rem",
                        color: "white",
                      }}
                    />
                  </Link>
                </motion.div>
                <motion.div
                  className="button-hover"
                  whileHover={{ scale: 1.03 }}
                  whileTap={{ scale: 0.98 }}
                  style={{
                    flex: isMobile ? "1" : "initial", // Make buttons take equal space on mobile
                    minWidth: isMobile ? "auto" : "initial",
                  }}
                >
                  <a
                    href="#trusted-by-id"
                    className="button secondary w-button"
                    style={{
                      padding: isMobile ? "8px 12px" : "10px 16px",
                      fontSize: isMobile ? "0.9rem" : "0.95rem",
                      width: isMobile ? "100%" : "auto",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Learn More{" "}
                    <ArrowForwardIcon
                      sx={{
                        fontSize: isMobile ? "1rem" : "1.2rem",
                        color: "inherit",
                      }}
                    />
                  </a>
                </motion.div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default HeroSection;
