import React from "react";
import { Link } from "react-router-dom";

function Disclaimer() {
  return (
    <div className="disclaimer-page">
      <div className="animated-bg">
        <div className="particle particle1"></div>
        <div className="particle particle2"></div>
        <div className="particle particle3"></div>
        <div className="particle particle4"></div>
        <div className="particle particle5"></div>
        <div className="gradient-overlay"></div>
      </div>

      <div
        className="disclaimer-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          padding: "40px 20px",
          position: "relative",
          zIndex: 10,
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            width: "100%",
            textAlign: "center",
            animation: "fadeIn 0.5s ease-in-out",
          }}
        >
          <div
            style={{
              padding: "40px",
              borderRadius: "20px",
              background: "rgba(23, 25, 35, 0.8)",
              backdropFilter: "blur(12px)",
              border: "1px solid rgba(255, 255, 255, 0.15)",
              boxShadow: "0 10px 40px rgba(0, 0, 0, 0.2), 0 0 30px rgba(0, 98, 255, 0.2)",
              marginBottom: "40px",
              position: "relative",
              overflow: "hidden",
            }}
          >
            {/* Glowing border */}
            <div className="glowing-border"></div>

            {/* Icon */}
            <div
              style={{
                width: "70px",
                height: "70px",
                borderRadius: "50%",
                background: "linear-gradient(135deg, #0062ff 0%, #00c6ff 100%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto 30px",
                boxShadow: "0 4px 20px rgba(0, 98, 255, 0.3)",
                animation: "pulse 2s infinite",
              }}
            >
              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 8V13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.995 16H12.005" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>

            <h1 style={{ fontSize: "28px", marginBottom: "15px", color: "#fff" }}>Specialized Browser Environment</h1>

            <p
              style={{
                fontSize: "16px",
                lineHeight: "1.6",
                marginBottom: "30px",
                color: "rgba(255, 255, 255, 0.9)",
                maxWidth: "600px",
                margin: "0 auto 30px",
              }}
            >
              The Edutools browser is specifically optimized for accessing premium educational tools in your subscription. To maintain maximum performance, web searches are not supported.
            </p>

            <div
              style={{
                background: "rgba(0, 98, 255, 0.1)",
                borderRadius: "12px",
                padding: "25px",
                marginBottom: "30px",
                border: "1px solid rgba(0, 98, 255, 0.2)",
                maxWidth: "500px",
                margin: "0 auto 30px",
                textAlign: "left",
              }}
            >
              <h3 style={{ fontSize: "18px", marginBottom: "15px", color: "#fff", display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "26px",
                    height: "26px",
                    borderRadius: "50%",
                    background: "linear-gradient(135deg, #0062ff 0%, #00c6ff 100%)",
                    marginRight: "10px",
                    flexShrink: 0,
                  }}
                >
                  <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8 12L11 15L16 10" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                How to use Edutools browser:
              </h3>
              <ul style={{ paddingLeft: "20px", margin: "0", listStyleType: "none" }}>
                <li style={{ marginBottom: "16px", position: "relative", paddingLeft: "16px", color: "rgba(255, 255, 255, 0.8)" }}>
                  <span style={{ position: "absolute", left: "0", top: "8px", width: "6px", height: "6px", borderRadius: "50%", background: "#0062ff" }}></span>
                  <span style={{ color: "#fff", fontWeight: "500" }}>Paste direct links</span> to premium educational content (Chegg, Quizlet, etc.)
                </li>
                <li style={{ marginBottom: "16px", position: "relative", paddingLeft: "16px", color: "rgba(255, 255, 255, 0.8)" }}>
                  <span style={{ position: "absolute", left: "0", top: "8px", width: "6px", height: "6px", borderRadius: "50%", background: "#0062ff" }}></span>
                  <span style={{ color: "#fff", fontWeight: "500" }}>Use launchpad shortcuts</span> to quickly access your favorite educational sites
                </li>
                <li style={{ position: "relative", paddingLeft: "16px", color: "rgba(255, 255, 255, 0.8)" }}>
                  <span style={{ position: "absolute", left: "0", top: "8px", width: "6px", height: "6px", borderRadius: "50%", background: "#0062ff" }}></span>
                  <span style={{ color: "#fff", fontWeight: "500" }}>Use your regular browser</span> for general web searches and everyday browsing
                </li>
              </ul>
            </div>

            <div style={{ display: "flex", justifyContent: "center", gap: "15px", flexWrap: "wrap" }}>
              <button
                onClick={() => {
                  try {
                    // Try the standard approach first
                    window.close();

                    // If we're still here, try alternative approaches
                    // For Chrome, some versions need both
                    setTimeout(() => {
                      // For some browsers, fallback to history
                      window.location.href = "about:blank";
                      window.history.back();
                    }, 100);
                  } catch (e) {
                    console.error("Error closing window:", e);
                    // Final fallback
                    window.history.back();
                  }
                }}
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "12px 24px",
                  background: "transparent",
                  border: "1px solid rgba(255, 255, 255, 0.2)",
                  borderRadius: "50px",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "500",
                  textDecoration: "none",
                  transition: "all 0.2s ease",
                  cursor: "pointer",
                }}
              >
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "8px" }}>
                  <path d="M18 6L6 18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6 6L18 18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Close Page
              </button>
              <Link
                to="/123hyperlinks123"
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "12px 24px",
                  background: "linear-gradient(135deg, #0062ff 0%, #00c6ff 100%)",
                  borderRadius: "50px",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "500",
                  textDecoration: "none",
                  transition: "all 0.2s ease",
                  border: "none",
                }}
              >
                Go to Launchpad
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: "8px" }}>
                  <path d="M14.43 5.93L20.5 12L14.43 18.07" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M3.5 12H20.33" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Add animation styles */}
      <style>
        {`
          .disclaimer-page {
            background: linear-gradient(125deg, #080a12, #13172a);
            min-height: 100vh;
            position: relative;
            overflow: hidden;
          }
          
          .animated-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          
          .gradient-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: 
              radial-gradient(circle at 20% 20%, rgba(0, 98, 255, 0.12) 0%, transparent 50%),
              radial-gradient(circle at 80% 80%, rgba(0, 200, 255, 0.12) 0%, transparent 50%);
            z-index: 1;
          }
          
          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
          
          @keyframes pulse {
            0% {
              box-shadow: 0 0 0 0 rgba(0, 98, 255, 0.6);
            }
            70% {
              box-shadow: 0 0 0 15px rgba(0, 98, 255, 0);
            }
            100% {
              box-shadow: 0 0 0 0 rgba(0, 98, 255, 0);
            }
          }
          
          .particle {
            position: absolute;
            border-radius: 50%;
            background: linear-gradient(135deg, #0062ff, #00c6ff);
            opacity: 0.25;
            z-index: 0;
          }
          
          .particle1 {
            width: 300px;
            height: 300px;
            top: -150px;
            left: -150px;
            animation: float 20s ease-in-out infinite;
            filter: blur(80px);
          }
          
          .particle2 {
            width: 250px;
            height: 250px;
            bottom: -125px;
            right: -125px;
            animation: float 25s ease-in-out infinite reverse;
            filter: blur(60px);
          }
          
          .particle3 {
            width: 200px;
            height: 200px;
            top: 40%;
            right: -100px;
            animation: float 18s ease-in-out infinite;
            filter: blur(50px);
          }
          
          .particle4 {
            width: 200px;
            height: 200px;
            bottom: 30%;
            left: -100px;
            animation: float 15s ease-in-out infinite reverse;
            filter: blur(50px);
          }
          
          .particle5 {
            width: 150px;
            height: 150px;
            top: -75px;
            right: 30%;
            animation: float 22s ease-in-out infinite;
            filter: blur(40px);
          }
          
          @keyframes float {
            0% {
              transform: translateY(0) translateX(0);
            }
            25% {
              transform: translateY(-40px) translateX(40px);
            }
            50% {
              transform: translateY(0) translateX(80px);
            }
            75% {
              transform: translateY(40px) translateX(40px);
            }
            100% {
              transform: translateY(0) translateX(0);
            }
          }
          
          .glowing-border {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 2px;
            background: linear-gradient(to right, transparent, #0062ff, #00c6ff, transparent);
            animation: glow 2s infinite;
            border-radius: 20px 20px 0 0;
          }
          
          @keyframes glow {
            0%, 100% {
              opacity: 0.5;
              box-shadow: 0 0 5px rgba(0, 98, 255, 0.5);
            }
            50% {
              opacity: 1;
              box-shadow: 0 0 15px rgba(0, 98, 255, 0.8);
            }
          }
        `}
      </style>
    </div>
  );
}

export default Disclaimer;
