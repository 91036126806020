import React from 'react';
import { Typography } from '@mui/material';
import { motion } from 'framer-motion';

/**
 * A component that displays a platform icon with a label
 */
const PlatformIcon = ({ icon, label }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: false, margin: "-50px" }}
      transition={{ duration: 0.5 }}
      whileHover={{ y: -10, transition: { duration: 0.2 } }}
      className="PlatformIcon"
    >
      <img src={icon} alt={label} />
      <Typography
        variant="h6"
        sx={{
          color: "white",
          fontSize: "1rem",
          fontWeight: 500,
        }}
      >
        {label}
      </Typography>
    </motion.div>
  );
};

export default PlatformIcon; 