import React from "react";

function PrivacyPolicy() {
  return (
    <div className="container" style={{ padding: "40px 20px" }}>
      <div style={{ maxWidth: "800px", margin: "0 auto", textAlign: "left" }}>
        <h1 style={{ textAlign: "center", marginBottom: "10px" }}>Privacy Policy</h1>
        <p style={{ textAlign: "center", marginBottom: "30px" }}>
          <strong>Last Updated: Feb 13, 2025</strong>
        </p>

        <div style={{ lineHeight: "1.6", fontSize: "16px" }}>
          <p style={{ marginBottom: "20px" }}>LearnOS LLC ("Company," "we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit and use our educational tools and services (collectively, the "Service"). By accessing or using the Service, you consent to the practices described in this Privacy Policy.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>1. Information We Collect</h2>
          <p style={{ marginBottom: "15px" }}>We collect various types of information to provide and improve our Service, including:</p>
          <p style={{ marginBottom: "10px", fontWeight: "500" }}>a. Personal Information</p>
          <ul style={{ marginBottom: "20px", paddingLeft: "30px" }}>
            <li style={{ marginBottom: "8px" }}>Name</li>
            <li style={{ marginBottom: "8px" }}>Email address</li>
            <li style={{ marginBottom: "8px" }}>Social media usernames (if voluntarily provided in bios)</li>
            <li style={{ marginBottom: "8px" }}>Payment information (processed securely by third-party payment providers)</li>
          </ul>
          <p style={{ marginBottom: "10px", fontWeight: "500" }}>b. Usage Data</p>
          <ul style={{ marginBottom: "20px", paddingLeft: "30px" }}>
            <li style={{ marginBottom: "8px" }}>IP address</li>
            <li style={{ marginBottom: "8px" }}>Device and browser information</li>
            <li style={{ marginBottom: "8px" }}>Log data including timestamps, accessed features, and interactions within the Service (any activity within the browser stays private)</li>
            <li style={{ marginBottom: "8px" }}>Chat logs (automatically deleted after 24 hours)</li>
          </ul>
          <p style={{ marginBottom: "10px", fontWeight: "500" }}>c. Third-Party Data Collection</p>
          <p style={{ marginBottom: "20px" }}>Certain third-party services integrated into the Service may collect tracking or behavioral data. LearnOS LLC is not responsible for third-party data collection practices.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>2. How We Use Your Information</h2>
          <p style={{ marginBottom: "15px" }}>We use the collected information for the following purposes:</p>
          <ul style={{ marginBottom: "20px", paddingLeft: "30px" }}>
            <li style={{ marginBottom: "8px" }}>To provide and improve the Service</li>
            <li style={{ marginBottom: "8px" }}>To facilitate account creation and authentication</li>
            <li style={{ marginBottom: "8px" }}>To process transactions and subscriptions</li>
            <li style={{ marginBottom: "8px" }}>To monitor and enforce compliance with our Terms of Service</li>
            <li style={{ marginBottom: "8px" }}>To enhance security</li>
            <li style={{ marginBottom: "8px" }}>To provide customer support and resolve inquiries</li>
            <li style={{ marginBottom: "8px" }}>To comply with legal obligations</li>
          </ul>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>3. Data Sharing and Disclosure</h2>
          <p style={{ marginBottom: "15px" }}>We do not sell or share your personal data ever. However, we may share information in the following circumstances:</p>
          <ul style={{ marginBottom: "20px", paddingLeft: "30px" }}>
            <li style={{ marginBottom: "8px" }}>
              <strong>With Third-Party Service Providers:</strong> To process payments, analyze usage, or provide technical infrastructure.
            </li>
            <li style={{ marginBottom: "8px" }}>
              <strong>Legal Compliance:</strong> If required by law, regulation, legal process, or government request.
            </li>
            <li style={{ marginBottom: "8px" }}>
              <strong>Business Transfers:</strong> In connection with a merger, acquisition, or sale of company assets.
            </li>
            <li style={{ marginBottom: "8px" }}>
              <strong>Enforcement of Terms:</strong> To protect the rights, safety, or property of LearnOS LLC, our users, or others.
            </li>
          </ul>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>4. Data Retention</h2>
          <p style={{ marginBottom: "10px" }}>We retain personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, comply with legal obligations, and resolve disputes.</p>
          <p style={{ marginBottom: "10px" }}>Chat messages are automatically deleted after 24 hours.</p>
          <p style={{ marginBottom: "20px" }}>Upon account deletion, we may retain certain data for compliance and security purposes.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>5. User Rights and Choices</h2>
          <p style={{ marginBottom: "15px" }}>Depending on your jurisdiction, you may have the right to:</p>
          <ul style={{ marginBottom: "20px", paddingLeft: "30px" }}>
            <li style={{ marginBottom: "8px" }}>Access, update, or delete your personal information</li>
            <li style={{ marginBottom: "8px" }}>Restrict or object to certain data processing</li>
            <li style={{ marginBottom: "8px" }}>Withdraw consent where processing is based on consent</li>
            <li style={{ marginBottom: "8px" }}>Request a copy of your data in a portable format</li>
          </ul>
          <p style={{ marginBottom: "20px" }}>To exercise your rights, contact us at contact@edutools.app. We may require verification before fulfilling requests.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>6. Security Measures</h2>
          <p style={{ marginBottom: "20px" }}>We implement reasonable security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. However, no data transmission or storage system is completely secure.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>7. Third-Party Links and Services</h2>
          <p style={{ marginBottom: "20px" }}>Our Service may contain links to third-party websites or services. This Privacy Policy does not apply to third-party services, and we are not responsible for their privacy practices.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>8. Children's Privacy</h2>
          <p style={{ marginBottom: "20px" }}>Our Service is not intended for individuals under 13 years of age. We do not knowingly collect personal information from children. If we become aware of such collection, we will take appropriate steps to remove the data.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>9. Changes to This Privacy Policy</h2>
          <p style={{ marginBottom: "20px" }}>We may update this Privacy Policy periodically. The "Last Updated" date reflects the most recent revisions. Continued use of the Service after updates constitutes acceptance of the revised policy.</p>

          <h2 style={{ marginTop: "30px", marginBottom: "15px", fontSize: "20px", fontWeight: "600" }}>10. Contact Us</h2>
          <p style={{ marginBottom: "10px" }}>If you have any questions or concerns regarding this Privacy Policy, please contact us at:</p>
          <div style={{ marginBottom: "40px", padding: "15px 0" }}>
            LearnOS LLC
            <br />
            contact@edutools.app
            <br />
            Wyoming, United States
            <br />
            32 N Gould St
            <br />
            Sheridan, 82801
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
