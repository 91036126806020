import React from "react";
import "aos/dist/aos.css";

function HyperLinks() {
  const services = [
    { id: 1, name: "ChatGPT", logo: "https://edutools-app.pages.dev/assets/chatgpt.png", link: "https://chatgpt.com/", description: "AI-powered writing and assistance tool." },
    { id: 2, name: "Grammarly", logo: "https://edutools-app.pages.dev/assets/grammarly.png", link: "https://app.grammarly.com/", description: "Grammar and spelling checker." },
    { id: 3, name: "Quizlet", logo: "https://edutools-app.pages.dev/assets/quizlet.png", link: "https://quizlet.com", description: "Flashcards and study tools." },
    { id: 4, name: "Chegg", logo: "https://edutools-app.pages.dev/assets/chegg.png", link: "https://www.chegg.com/", description: "Homework help and textbook solutions." },
    { id: 5, name: "Canva", logo: "https://edutools-app.pages.dev/assets/canva.png", link: "https://www.canva.com/", description: "Graphic design made simple." },
    { id: 6, name: "SparkNotes", logo: "https://edutools-app.pages.dev/assets/sparknotes.png", link: "https://www.sparknotes.com/", description: "Literature summaries and guides." },
    { id: 7, name: "Wolfram Alpha", logo: "https://edutools-app.pages.dev/assets/wolframalpha.png", link: "https://www.wolframalpha.com/", description: "Computational knowledge engine." },
    { id: 8, name: "GPTZero", logo: "https://edutools-app.pages.dev/assets/gptzero.png", link: "https://app.gptzero.me/", description: "Detects AI-generated content." },
  ];

  return (
    <div
      style={{
        color: "white",
        margin: 0,
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "-15%",
          left: "50%",
          width: "115.5%",
          height: "115.5%",
          transform: "translateX(-50%)",
          background: "radial-gradient(circle at 50% 0%, rgba(30, 64, 175, 0.25), transparent 60%)",
          pointerEvents: "none",
          zIndex: 0,
        }}
      />
      <h1
        className="gradient-title"
        style={{
          textAlign: "center",
          marginTop: "-120px",
          fontFamily: "'Inter', sans-serif",
          fontWeight: 700,
          letterSpacing: "-0.05em",
          position: "relative",
          zIndex: 1,
        }}
      >
        EduTools Launchpad
      </h1>
      <p style={{ textAlign: "center", marginTop: "10px", marginBottom: "50px", fontSize: "17px", color: "#cccccc" }}>Click on an app to open it and use it in a new tab!</p>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "20px",
          margin: "0 auto",
          maxWidth: "800px",
        }}
      >
        {services.map((service) => (
          <div
            key={service.id}
            style={{
              background: "radial-gradient(circle at 50% 200%, #34353928, #17181a00 61%), radial-gradient(circle at 50% -60%, #34353975, #17181a00 75%), radial-gradient(circle at 50% 50%, #1e3a8a30, transparent 100%)",
              border: "1px solid #ffffff1a",
              color: "white",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "180px",
              width: "180px",
              cursor: service.underMaintenance ? "default" : "pointer",
              transition: "background-color 0.3s",
              textAlign: "center",
              padding: "10px",
              position: "relative",
              overflow: "hidden",
              opacity: service.underMaintenance ? 0.5 : 1,
              filter: service.underMaintenance ? "grayscale(70%)" : "none",
            }}
            onClick={() => !service.underMaintenance && window.open(service.link, "_blank")}
            onMouseEnter={(e) => !service.underMaintenance && (e.currentTarget.style.background = "#333333")}
            onMouseLeave={(e) => !service.underMaintenance && (e.currentTarget.style.background = "radial-gradient(circle at 50% 200%, #34353928, #17181a00 61%), radial-gradient(circle at 50% -60%, #34353975, #17181a00 75%), radial-gradient(circle at 50% 50%, #1e3a8a30, transparent 100%)")}
          >
            {service.underMaintenance && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(100, 100, 100, 0.2)",
                  zIndex: 2,
                  borderRadius: "8px",
                }}
              />
            )}
            <img
              src={service.logo}
              alt={`${service.name} Logo`}
              style={{
                position: "relative",
                zIndex: 3,
                width: "60px",
                height: "60px",
                marginBottom: "10px",
              }}
            />
            <img src={service.logo} alt={`${service.name} Logo`} style={{ position: "absolute", top: "50%", left: "calc(100% - 20px)", transform: "translate(-50%, -50%) scale(1.4)", width: "100%", height: "100%", zIndex: 1, opacity: 0.1, filter: "blur(5px)" }} />
            <span style={{ position: "relative", zIndex: 3, fontWeight: 500, fontSize: "1.2em" }}>{service.name}</span>
            {service.underMaintenance && (
              <span
                style={{
                  position: "relative",
                  zIndex: 3,
                  fontWeight: 400,
                  fontSize: "0.8em",
                  color: "#ff9800",
                  marginTop: "5px",
                  padding: "2px 8px",
                  backgroundColor: "rgba(255, 152, 0, 0.1)",
                  borderRadius: "4px",
                  border: "1px solid rgba(255, 152, 0, 0.3)",
                }}
              >
                Under Maintenance
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default HyperLinks;
