import React, { useState } from "react";
import { Box, Typography, Container, Grid, Paper, Chip, Button, Divider, Snackbar, Alert, Stack } from "@mui/material";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import logoImage from "../images/logo_1.png"; // Import for the footer logo
import snapIcon from "../assets/logos/snapicon.png";
import instagramIcon from "../assets/logos/instagram.png";
import appleIcon from "../images/apple.png"; // Import Apple icon for the button

const Info = () => {
  // Add state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // Premium subscriptions data from HyperLinks.js
  const premiumServices = [
    {
      id: 1,
      name: "ChatGPT",
      proName: "Plus",
      logo: "https://edutools-app.pages.dev/assets/chatgpt.png",
      description: "AI writing assistant",
    },
    {
      id: 2,
      name: "Grammarly",
      proName: "Premium",
      logo: "https://edutools-app.pages.dev/assets/grammarly.png",
      description: "Grammar checker",
    },
    {
      id: 3,
      name: "Quizlet",
      proName: "Plus",
      logo: "https://edutools-app.pages.dev/assets/quizlet.png",
      description: "Study tools",
    },
    {
      id: 4,
      name: "Chegg",
      proName: "Pro",
      logo: "https://edutools-app.pages.dev/assets/chegg.png",
      description: "Homework help",
    },
    {
      id: 5,
      name: "Canva",
      proName: "Pro",
      logo: "https://edutools-app.pages.dev/assets/canva.png",
      description: "Design tool",
    },
    {
      id: 6,
      name: "SparkNotes",
      proName: "Plus",
      logo: "https://edutools-app.pages.dev/assets/sparknotes.png",
      description: "Literature guides",
    },
    {
      id: 7,
      name: "Wolfram Alpha",
      proName: "Pro",
      logo: "https://edutools-app.pages.dev/assets/wolframalpha.png",
      description: "Computational engine",
    },
    {
      id: 8,
      name: "More",
      proName: "Coming Soon",
      logo: "https://em-content.zobj.net/source/apple/81/eyes_1f440.png",
      description: "Based on requests, we are always adding to our suite of tools for our users",
      hideProChip: true,
    },
  ];

  return (
    <Box sx={{ width: "100%", minHeight: "100vh" }}>
      <Navbar />
      <Container maxWidth="sm">
        {/* Hero Section */}
        <Box
          sx={{
            width: "100%",
            padding: "40px 20px 0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginTop: "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              sx={{
                fontWeight: 700,
                color: "#fff",
                lineHeight: 1.2,
                fontSize: { xs: "1.8rem", sm: "2.2rem" },
                marginBottom: "15px",
              }}
            >
              You've just found the <i>secret</i> to getting all A's
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 1,
                mb: 3,
              }}
            >
              <img
                src="https://em-content.zobj.net/source/apple/325/shushing-face_1f92b.png"
                alt="Shushing Face Emoji"
                style={{
                  width: "2.5em",
                  height: "2.5em",
                  marginRight: "15px",
                }}
              />
              <img
                src="https://em-content.zobj.net/source/apple/114/rocket_1f680.png"
                alt="Rocket Emoji"
                style={{
                  width: "2.5em",
                  height: "2.5em",
                }}
              />
            </Box>
            <Divider
              sx={{
                width: "80%",
                borderColor: "rgba(255, 255, 255, 0.1)",
                background: "linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.15), transparent)",
                height: "1px",
                mb: 3,
              }}
            />
          </Box>
        </Box>

        {/* Premium Subscriptions Section */}
        <Box sx={{ mb: 4 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h4"
              component="h2"
              sx={{
                fontWeight: 700,
                color: "#fff",
                lineHeight: 1.2,
                fontSize: { xs: "1.8rem", sm: "2.2rem" },
                mb: 0.5,
              }}
            >
              All these <span className="gradient-text">fully upgraded</span>
              <br />
              subscriptions
            </Typography>

            <Typography
              variant="h5"
              component="h3"
              sx={{
                fontWeight: 500,
                color: "#fff",
                mb: 4,
                opacity: 0.9,
                fontSize: "1.3rem",
                display: "inline",
              }}
            >
              for the price of eating out once...
            </Typography>
          </Box>

          <Grid container spacing={2}>
            {premiumServices.map((service) => (
              <Grid item xs={6} key={service.id}>
                <Paper
                  elevation={0}
                  sx={{
                    position: "relative",
                    p: 2,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    background: "radial-gradient(circle at 50% 50%, #1e3a8a30, transparent 100%)",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  {!service.hideProChip && (
                    <Chip
                      label="PRO"
                      size="small"
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        backgroundColor: "rgba(0, 98, 255, 0.15)",
                        color: "#fff",
                        fontWeight: 500,
                        fontSize: "0.6rem",
                        border: "1px solid rgba(0, 98, 255, 0.3)",
                        zIndex: 2,
                        "& .MuiChip-label": {
                          padding: "0 8px",
                        },
                      }}
                    />
                  )}
                  <Box sx={{ mt: 1, mb: 1 }}>
                    <img src={service.logo} alt={`${service.name} Logo`} style={{ width: "40px", height: "40px" }} />
                  </Box>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      color: "#fff",
                      fontSize: "0.9rem",
                      lineHeight: 1.2,
                    }}
                  >
                    {service.name} {service.proName}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                    sx={{
                      fontSize: service.id === 8 ? "0.65rem" : "0.7rem",
                      color: "rgba(255, 255, 255, 0.7)",
                      mt: 0.5,
                    }}
                  >
                    {service.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Student Struggles Section (formerly Lorem Ipsum) */}
        <Box sx={{ mt: 6, mb: 4 }}>
          <Typography
            variant="body1"
            align="center"
            sx={{
              color: "rgba(255, 255, 255, 0.7)",
              mb: 4,
              px: 2,
              lineHeight: 1.6,
              fontSize: "0.95rem",
            }}
          >
            As students ourselves, we know the struggle. Searching for homework answers for assignments due at 11:59pm only for them to be blocked, being locked out of unlimited Quizlet test tools, dealing with Canva watermarks when trying to make that goated presentation, and not being able to upload files and long strings of text to GPT. We've been there, and we know how frustrating it can be. That's why we made EduTools. It's helped countless students kick school in the ass, and it can help you too. What are you waiting for?
          </Typography>
        </Box>

        {/* Get Started Button (New) */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 5,
            mt: 2,
            position: "relative",
            zIndex: 10,
          }}
        >
          <Link to="/download-app" style={{ textDecoration: "none", display: "block" }}>
            <div className="nav-button-holder" style={{ display: "block" }}>
              <Link
                to="/download-app"
                className="button"
                style={{
                  fontSize: "20px",
                  padding: "16px 28px",
                  minWidth: "220px",
                  display: "inline-block !important",
                  fontWeight: "600",
                  borderRadius: "12px",
                  visibility: "visible !important",
                  opacity: "1 !important",
                  position: "relative",
                  zIndex: "100",
                  backgroundColor: "#007fff !important",
                }}
              >
                Let's Get Started
                <img
                  src="https://em-content.zobj.net/source/apple/96/rocket_1f680.png"
                  alt="Rocket Emoji"
                  style={{
                    marginLeft: "8px",
                    width: "20px",
                    height: "20px",
                    verticalAlign: "middle",
                  }}
                />
              </Link>
            </div>
          </Link>
        </Box>

        {/* Contact Section */}
        <Box sx={{ mt: 4, mb: 6, textAlign: "center" }}>
          <Typography
            variant="h5"
            component="h3"
            sx={{
              fontWeight: 600,
              color: "#fff",
              mb: 3,
            }}
          >
            Any questions? Contact us
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "center", gap: 4, mb: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <img src={snapIcon} alt="Snapchat" style={{ width: "24px", height: "24px" }} />
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText("edutools_snap");
                    setSnackbarMessage("Snapchat username copied to clipboard!");
                    setSnackbarOpen(true);
                  }}
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    cursor: "pointer",
                    fontWeight: "500",
                    borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                    paddingBottom: "2px",
                    transition: "all 0.2s ease",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.color = "#FFFC00"; // Snapchat yellow
                    e.target.style.borderBottom = "1px solid #FFFC00";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.color = "#fff";
                    e.target.style.borderBottom = "1px solid rgba(255, 255, 255, 0.3)";
                  }}
                >
                  edutools_snap
                </a>
              </Box>
              <Typography variant="caption" sx={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "0.7rem" }}>
                tap to copy snap
              </Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <img src={instagramIcon} alt="Instagram" style={{ width: "24px", height: "24px" }} />
                <a
                  href="https://www.instagram.com/edutoo1s/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    cursor: "pointer",
                    fontWeight: "500",
                    borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                    paddingBottom: "2px",
                    transition: "all 0.2s ease",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.color = "#0062ff";
                    e.target.style.borderBottom = "1px solid #0062ff";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.color = "#fff";
                    e.target.style.borderBottom = "1px solid rgba(255, 255, 255, 0.3)";
                  }}
                >
                  @edutoo1s
                </a>
              </Box>
              <Typography variant="caption" sx={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "0.7rem" }}>
                tap to go to our insta
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>

      {/* Footer Section - Redesigned */}
      <Box
        component="footer"
        sx={{
          mt: 8,
          py: 6,
          background: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(9,9,11,0.8) 100%)",
          borderTop: "1px solid rgba(255,255,255,0.08)",
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} justifyContent="space-between">
            <Grid item xs={12} md={4} sx={{ mb: { xs: 4, md: 0 } }}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <img src={logoImage} alt="EduTools Logo" style={{ width: 40, height: 40, marginRight: 12 }} />
                <Typography variant="h6" sx={{ fontWeight: 700, color: "white" }}>
                  EduTools
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ color: "rgba(255,255,255,0.7)", mb: 2 }}>
                The only study subscription you will ever need. Save time, get better grades, and become an academic weapon.
              </Typography>
            </Grid>

            <Grid item xs={6} md={2}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700, color: "white", mb: 2 }}>
                Links
              </Typography>
              <Stack spacing={1}>
                <Link to="/" style={{ color: "rgba(255,255,255,0.7)", textDecoration: "none", transition: "color 0.2s ease", "&:hover": { color: "#0062ff" } }}>
                  Home
                </Link>
                <Link to="/download-app" style={{ color: "rgba(255,255,255,0.7)", textDecoration: "none", transition: "color 0.2s ease", "&:hover": { color: "#0062ff" } }}>
                  Download
                </Link>
              </Stack>
            </Grid>

            <Grid item xs={6} md={2}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700, color: "white", mb: 2 }}>
                Legal
              </Typography>
              <Stack spacing={1}>
                <Link to="/terms-of-service" style={{ color: "rgba(255,255,255,0.7)", textDecoration: "none", transition: "color 0.2s ease", "&:hover": { color: "#0062ff" } }}>
                  Terms of Service
                </Link>
                <Link to="/privacy-policy" style={{ color: "rgba(255,255,255,0.7)", textDecoration: "none", transition: "color 0.2s ease", "&:hover": { color: "#0062ff" } }}>
                  Privacy Policy
                </Link>
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700, color: "white", mb: 2 }}>
                Contact
              </Typography>
              <Typography variant="body2" sx={{ color: "rgba(255,255,255,0.7)", mb: 1 }}>
                Have questions or feedback?
              </Typography>
              <a
                href="mailto:contact@edutools.app"
                style={{
                  color: "#0062ff",
                  textDecoration: "none",
                  display: "inline-block",
                  borderBottom: "1px solid rgba(0,98,255,0.3)",
                  paddingBottom: "2px",
                }}
              >
                contact@edutools.app
              </a>
            </Grid>
          </Grid>

          <Divider sx={{ my: 4, borderColor: "rgba(255,255,255,0.08)" }} />

          <Typography variant="body2" align="center" sx={{ color: "rgba(255,255,255,0.5)" }}>
            © {new Date().getFullYear()} LearnOS LLC. All rights reserved.
          </Typography>
        </Container>
      </Box>

      {/* Snackbar for clipboard copy notification */}
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
        <Alert onClose={handleSnackbarClose} severity="success" variant="filled">
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Add keyframe animation for the gradient text */}
      <style>
        {`
          .gradient-text {
            background: linear-gradient(135deg, #0062ff 0%, #00c6ff 50%, #0062ff 100%);
            background-size: 200% auto;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-shadow: 0 2px 10px rgba(0,150,255,0.3);
            animation: shine 2s linear infinite;
          }
          
          @keyframes shine {
            0% {
              background-position: 0% 50%;
            }
            50% {
              background-position: 100% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default Info;
