import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

/**
 * Call to action section that encourages users to take action
 */
const CallToActionSection = () => {
  return (
    <div className="section" data-aos="fade-up">
      <div className="container">
        <div className="cta-holder-06">
          <div className="cta-left-right-grid">
            <div className="fade-in-on-scroll">
              <div className="cta-heading-holder ease-larger">
                <h3 className="second-title">
                  Become an academic weapon.
                  <br />
                  Start using our app today.
                </h3>
              </div>
            </div>
            <div className="fade-in-on-scroll">
              <div className="cta-button-holder">
                <motion.a
                  to="/download-app"
                  className="button secondary w-inline-block"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <div>Explore</div>
                  <img
                    loading="lazy"
                    src="./images/Arrow-Right.svg"
                    alt="Arrow Right"
                    className="button-arrow-icon"
                  />
                </motion.a>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <Link to="/download-app" className="button">
                    <div>Download</div>
                  </Link>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToActionSection; 