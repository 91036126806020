import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaMoneyBillWave, FaUsers, FaHeadset } from "react-icons/fa";

const ValuePropositionSection = () => {
  console.log("ValuePropositionSection rendering");

  // Track window width for responsive adjustments
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  );

  // Update width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Determine if mobile view
  const isMobile = windowWidth < 768;

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.1,
      },
    },
  };

  return (
    <section
      style={{
        width: "100%", // Full width
        padding: isMobile ? "30px 0 40px" : "40px 0 60px", // Reduced top padding, preserved bottom padding
        background:
          "linear-gradient(135deg, rgba(185, 185, 185, 0.05) 0%, rgba(255, 255, 255, 0.005) 100%)",
        backdropFilter: "blur(12px)",
        WebkitBackdropFilter: "blur(12px)",
        borderRadius: "0", // No border radius
        border: "1px solid rgba(255, 255, 255, 0.04)",
        borderLeft: "0", // Remove side borders
        borderRight: "0", // Remove side borders
        boxShadow: "0 8px 32px 0 rgba(0, 0, 0, 0.02)",
        position: "relative",
        zIndex: 5,
        overflow: "hidden",
      }}
    >
      {/* Glowing orb decoration - even more transparent */}
      <div
        style={{
          position: "absolute",
          top: "-50px",
          right: "5%",
          width: "200px",
          height: "200px",
          borderRadius: "50%",
          background:
            "radial-gradient(circle, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 50%, transparent 70%)",
          filter: "blur(45px)",
          zIndex: -1,
        }}
      />

      {/* Second glow effect for added depth - even more transparent */}
      <div
        style={{
          position: "absolute",
          bottom: "-30px",
          left: "5%",
          width: "180px",
          height: "180px",
          borderRadius: "50%",
          background:
            "radial-gradient(circle, rgba(255, 255, 255, 0.035) 0%, rgba(255, 255, 255, 0.015) 50%, transparent 70%)",
          filter: "blur(45px)",
          zIndex: -1,
        }}
      />

      {/* Content container with appropriate width */}
      <div
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          padding: isMobile ? "0 5%" : "0 7%",
        }}
      >
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, margin: "-100px" }}
          variants={staggerContainer}
        >
          {/* Title animation */}
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false }}
            transition={{ duration: 0.7, ease: "easeOut" }}
            style={{
              fontSize: isMobile
                ? "clamp(1.5rem, 6vw, 2.2rem)"
                : "clamp(2rem, 5vw, 2.75rem)",
              fontWeight: "700",
              textAlign: "center",
              marginTop: 0, // Ensure no extra top margin
              marginBottom: isMobile ? "30px" : "40px", // Keep same bottom margin
              color: "rgba(255, 255, 255, 0.95)",
              textShadow: "0 0 20px rgba(255, 255, 255, 0.08)",
            }}
          >
            <strong>
              All Your Study Tools -{" "}
              <span
                style={{
                  color: "#01D6FD",
                  background: "linear-gradient(to right, #01D6FD, #007CFF)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                One Subscription
              </span>
            </strong>
          </motion.h2>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: isMobile
                ? "1fr"
                : "repeat(auto-fit, minmax(280px, 1fr))",
              gap: isMobile ? "15px" : "20px",
            }}
          >
            {/* Save Money Feature - with icons in top right */}
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false, margin: "-100px" }}
              transition={{ duration: 0.6, delay: 0.1 }}
              style={{
                padding: isMobile ? "20px" : "25px",
                borderRadius: "12px",
                background:
                  "linear-gradient(145deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.06))",
                border: "1px solid rgba(255, 255, 255, 0.08)",
                boxShadow:
                  "inset 0 1px 1px rgba(255, 255, 255, 0.1), 0 2px 8px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease",
                height: "100%",
                position: "relative",
                overflow: "hidden",
              }}
              whileHover={{
                transform: "translateY(-5px)",
                boxShadow:
                  "inset 0 1px 2px rgba(255, 255, 255, 0.15), 0 10px 25px rgba(120, 120, 120, 0.15)",
                border: "1px solid rgba(255, 255, 255, 0.12)",
                background:
                  "linear-gradient(145deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.08))",
              }}
            >
              {/* Add subtle inset gradient */}
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background:
                    "radial-gradient(circle at top left, rgba(255, 255, 255, 0.08) 0%, transparent 60%)",
                  pointerEvents: "none",
                }}
              />

              {/* Title and icon container */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  marginBottom: "15px",
                  position: "relative",
                }}
              >
                <h3
                  style={{
                    fontWeight: "600",
                    color: "rgba(255, 255, 255, 0.9)",
                    fontSize: isMobile
                      ? "1.1rem"
                      : "clamp(1.1rem, 3vw, 1.3rem)",
                    margin: 0,
                  }}
                >
                  Save Money
                </h3>
                <div
                  style={{
                    color: "#007CFF",
                  }}
                >
                  <FaMoneyBillWave size={isMobile ? 24 : 30} />
                </div>
              </div>

              <p
                style={{
                  lineHeight: "1.6",
                  color: "rgba(255, 255, 255, 0.65)",
                  fontSize: isMobile ? "0.95rem" : "1rem",
                  position: "relative",
                }}
              >
                Stop paying for multiple study subscriptions. Get access to all
                the tools you need with one affordable subscription and save
                hundreds of dollars each semester.
              </p>
            </motion.div>

            {/* Community Feature - with icons in top right */}
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false, margin: "-100px" }}
              transition={{ duration: 0.6, delay: 0.2 }}
              style={{
                padding: isMobile ? "20px" : "25px",
                borderRadius: "12px",
                background:
                  "linear-gradient(145deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.06))",
                border: "1px solid rgba(255, 255, 255, 0.08)",
                boxShadow:
                  "inset 0 1px 1px rgba(255, 255, 255, 0.1), 0 2px 8px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease",
                height: "100%",
                position: "relative",
                overflow: "hidden",
              }}
              whileHover={{
                transform: "translateY(-5px)",
                boxShadow:
                  "inset 0 1px 2px rgba(255, 255, 255, 0.15), 0 10px 25px rgba(120, 120, 120, 0.15)",
                border: "1px solid rgba(255, 255, 255, 0.12)",
                background:
                  "linear-gradient(145deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.08))",
              }}
            >
              {/* Add subtle inset gradient */}
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background:
                    "radial-gradient(circle at top right, rgba(255, 255, 255, 0.08) 0%, transparent 60%)",
                  pointerEvents: "none",
                }}
              />

              {/* Title and icon container */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  marginBottom: "15px",
                  position: "relative",
                }}
              >
                <h3
                  style={{
                    fontWeight: "600",
                    color: "rgba(255, 255, 255, 0.9)",
                    fontSize: isMobile
                      ? "1.1rem"
                      : "clamp(1.1rem, 3vw, 1.3rem)",
                    margin: 0,
                  }}
                >
                  Join Our Community
                </h3>
                <div
                  style={{
                    color: "#007CFF",
                  }}
                >
                  <FaUsers size={isMobile ? 24 : 30} />
                </div>
              </div>

              <p
                style={{
                  lineHeight: "1.6",
                  color: "rgba(255, 255, 255, 0.65)",
                  fontSize: isMobile ? "0.95rem" : "1rem",
                  position: "relative",
                }}
              >
                Connect with thousands of students just like you. Share study
                resources, collaborate on projects, and build your network with
                our growing community of learners.
              </p>
            </motion.div>

            {/* Support Feature - with icons in top right */}
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false, margin: "-100px" }}
              transition={{ duration: 0.6, delay: 0.3 }}
              style={{
                padding: isMobile ? "20px" : "25px",
                borderRadius: "12px",
                background:
                  "linear-gradient(145deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.06))",
                border: "1px solid rgba(255, 255, 255, 0.08)",
                boxShadow:
                  "inset 0 1px 1px rgba(255, 255, 255, 0.1), 0 2px 8px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease",
                height: "100%",
                position: "relative",
                overflow: "hidden",
              }}
              whileHover={{
                transform: "translateY(-5px)",
                boxShadow:
                  "inset 0 1px 2px rgba(255, 255, 255, 0.15), 0 10px 25px rgba(120, 120, 120, 0.15)",
                border: "1px solid rgba(255, 255, 255, 0.12)",
                background:
                  "linear-gradient(145deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.08))",
              }}
            >
              {/* Add subtle inset gradient */}
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background:
                    "radial-gradient(circle at bottom right, rgba(255, 255, 255, 0.08) 0%, transparent 60%)",
                  pointerEvents: "none",
                }}
              />

              {/* Title and icon container */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  marginBottom: "15px",
                  position: "relative",
                }}
              >
                <h3
                  style={{
                    fontWeight: "600",
                    color: "rgba(255, 255, 255, 0.9)",
                    fontSize: isMobile
                      ? "1.1rem"
                      : "clamp(1.1rem, 3vw, 1.3rem)",
                    margin: 0,
                  }}
                >
                  24/7 Support
                </h3>
                <div
                  style={{
                    color: "#007CFF",
                  }}
                >
                  <FaHeadset size={isMobile ? 24 : 30} />
                </div>
              </div>

              <p
                style={{
                  lineHeight: "1.6",
                  color: "rgba(255, 255, 255, 0.65)",
                  fontSize: isMobile ? "0.95rem" : "1rem",
                  position: "relative",
                }}
              >
                Never feel stuck again. Our dedicated support team is available
                around the clock to help with any questions or issues through
                our ticket system.
              </p>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default ValuePropositionSection;
